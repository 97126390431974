<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Sub Location</strong>
          </CCardHeader>
          <CRow>
            <CCol md="8">
              <CCardBody>
                <CForm>
                  <CInput
                    label="Sub Location Name "
                    v-model="subLocation.subLocationName"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Sub Location Name'"
                    @blur="$v.subLocation.subLocationName.$touch()"
                    :is-valid="
                      $v.subLocation.subLocationName.$dirty
                        ? !$v.subLocation.subLocationName.$error
                        : null
                    "
                  />
                </CForm>
              </CCardBody>
            </CCol>
          </CRow>

          <CCardFooter align="right">
            <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" />Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="Save()"
            >
              <CIcon name="cil-check-circle" />Save
            </CButton>

            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" />Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";

import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "Sub Location",
  data() {
    return {
      mode: {
        newMode: false,
        editMode: false,
        viewMode: false,
      },

      subLocationId: "",
      subLocation: {
        locationId: this.$route.params.locationId,
        subLocationId: "0",
        subLocationName: "",
      },

      horizontal: { label: "col-3", input: "col-9" },
    };
  },

  validations: {
    subLocation: {
      subLocationName: {
        required,
      },
    },
  },
  created() {
    if (this.$route.params.mode == "new") {
      this.mode.newMode = true;
      this.mode.viewMode = false;
      this.mode.editMode = false;
      this.locationId = this.$route.params.locationId;
    }
    if (this.$route.params.mode == "view") {
      this.mode.viewMode = true;
      this.locationId = this.$route.params.locationId;
      this.subLocationId = this.$route.params.subLocationId;
      this.getUser();
    }
    if (this.$route.params.mode == "edit") {
      this.mode.editMode = true;
      this.subLocationId = this.$route.params.subLocationId;
      this.locationId = this.$route.params.locationId;
      this.getUser();
    }
  },

  methods: {
    editMode() {
      this.mode.editMode = true;
      this.mode.viewMode = false;
    },
    cancel() {
      this.$router.push({
        name: "Sub Locations",
        params: { locationId: this.$route.params.locationId },
      });
    },

    Save() {
      this.$v.subLocation.$touch();
      if (this.$v.subLocation.$invalid) {
        console.log("form is invalid", JSON.stringify(this.$v.subLocation));
      } else {
        // fetch(process.env.VUE_APP_API_HOST + "/emapp/web/secure/save/SubLocation", {
        //   method: "POST",
        //   headers: {
        //     "Content-type": "application/json",
        //   },
        //   body: JSON.stringify(this.subLocation),
        // })

        this.apiPostSecure(
          process.env.VUE_APP_API_HOST + "/emapp/web/secure/save/SubLocation",
          this.subLocation
        ).then((subLocation) => {
          console.log(JSON.stringify(subLocation));

          this.$router.push({
            name: "Sub Locations",
            params: { locationId: this.$route.params.locationId },
          });
        });
      }
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },

    getUser() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/subLocation/" +
          this.subLocationId
      )
        .then((response) => response.json())
        .then((data) => (this.subLocation = data));
    },
  },
  mounted() {},
};
</script>
